import React, {createContext, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {createContextualCan} from "@casl/react";
import {AbilityBuilder, createMongoAbility} from "@casl/ability";
import {useUser} from "../components/user/Context/UserProvider";
import {useQuery} from "@apollo/client";
import {GET_POLICIES_WITHOUT_ROLES} from "../graphql/policyQueries";
import {LinearProgress} from "@mui/material";
import Loader from "../components/Loader";
import {findWhere, isEmpty, map} from "underscore";

const ADMIN_ROLE_ID = 1;

const AuthzContext = createContext(undefined);
const Can = createContextualCan(AuthzContext.Consumer);

const applyPolicy = ({accessPolicy, user, can, cannot}) => {
  const conditionString = JSON.stringify(accessPolicy.conditions);
  const substitutedPolicyString = conditionString.replace(
    "\"#{userModel.id}\"",
    user?.id || 0,
  );
  const substitutedCondition = JSON.parse(substitutedPolicyString);

  const policyFunc = accessPolicy.inverted ? cannot : can;

  policyFunc(
    accessPolicy.action,
    accessPolicy.subject,
    !isEmpty(accessPolicy.fields) ? accessPolicy.fields : undefined,
    !isEmpty(substitutedCondition) ? substitutedCondition : undefined,
  );
};

const applyPolicies = (accessPolicies, user, can, cannot) => {
  if (isEmpty(accessPolicies)) return;
  for (const policy of accessPolicies) {
    if (policy?.isDisabled || policy?.accessPolicy?.isDisabled) continue;

    applyPolicy({
      accessPolicy: policy?.accessPolicy ?? policy,
      user,
      can,
      cannot,
    });
  }
};

const MemoryAuthzProvider = ({children}) => {
  if (process?.env?.NODE_ENV?.toLowerCase() !== "test") {
    return <AuthzProvider>{children}</AuthzProvider>;
  }
  const {can, rules} = new AbilityBuilder(createMongoAbility);
  can("findFirst", "all");
  can("findMany", "all");
  can("findUnique", "all");
  can("aggregate", "all");

  can("update", "all");
  can("updateMany", "all");
  can("create", "all");
  can("createMany", "all");
  can("delete", "all");
  can("deleteMany", "all");
  return (
    <AuthzContext.Provider value={createMongoAbility(rules)}>
      {children}
    </AuthzContext.Provider>
  );
};

MemoryAuthzProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const AuthzProvider = ({children}) => {
  const [ability, setAbility] = useState();
  const {data: policiesWithoutRolesData} = useQuery(GET_POLICIES_WITHOUT_ROLES);

  const {user} = useUser();

  useEffect(() => {
    if (isEmpty(user)) return;
    const {can, cannot, rules} = new AbilityBuilder(createMongoAbility);

    can("findFirst", "all");
    can("findMany", "all");
    can("findUnique", "all");
    can("aggregate", "all");

    can("update", "all");
    can("updateMany", "all");
    can("create", "all");
    can("createMany", "all");

    const userAdminRole = findWhere(
      map(user?.roles, (userRole) => userRole.role),
      {id: ADMIN_ROLE_ID},
    );

    cannot("update", "Role", {id: ADMIN_ROLE_ID});
    cannot("delete", "Role", {id: ADMIN_ROLE_ID});

    if (!isEmpty(userAdminRole)) {
      can("delete", "all");
      can("deleteMany", "all");
      can("update", "Role", ["name", "description", "users"], {
        id: ADMIN_ROLE_ID,
      });
      applyPolicies(userAdminRole?.accessPolicies, user, can, cannot);
    } else {
      cannot("create", "Activity");
      cannot("update", "Activity");
      for (const role of user?.roles || []) {
        applyPolicies(role.role?.accessPolicies, user, can, cannot);
      }
      applyPolicies(
        policiesWithoutRolesData?.accessPolicies,
        user,
        can,
        cannot,
      );
    }
    setAbility(createMongoAbility(rules));
  }, [policiesWithoutRolesData, user]);

  if (isEmpty(ability) || isEmpty(user)) {
    const progressValue = (ability && !user) || (user && !ability) ? 100 : 0;
    return (
      <>
        <LinearProgress variant="determinate" value={progressValue} />
        <Loader fullViewingHeight />
      </>
    );
  }

  return (
    <AuthzContext.Provider value={ability}>{children}</AuthzContext.Provider>
  );
};

AuthzProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useAuthz = () => {
  return useContext(AuthzContext);
};

export {AuthzProvider, MemoryAuthzProvider, useAuthz, Can};
