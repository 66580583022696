import {gql} from "@apollo/client";
import {CORE_CHAT_FIELDS} from "./chatQueries";
import {PRIMARY_USER_FIELDS} from "./userQueries";

const CORE_ACTIVITY_FIELDS = gql`
  ${PRIMARY_USER_FIELDS}
  fragment CoreActivityFields on Activity {
    id
    name
    description
    status
    updatedAt
    history {
      id
      user {
        ...PrimaryUserFields
      }
      event
      timestamp
    }
  }
`;

const CORE_ACTION_FIELDS = gql`
  ${PRIMARY_USER_FIELDS}
  ${CORE_ACTIVITY_FIELDS}
  ${CORE_CHAT_FIELDS}
  fragment CoreActionFields on Action {
    id
    isDeleted
    name
    description
    actionType
    acceptedAt
    deadlineAt
    nextRecursAt
    createdAt
    occurredAt
    updatedAt
    recurrence
    incident {
      id
      name
      isDeleted
      isArchived
      isRedacted
      users {
        id
        userId
      }
      roles {
        id
        roleId
      }
    }
    task {
      id
      name
      description
      template
      useTemplateOnce
    }
    user {
      ...PrimaryUserFields
    }
    phase {
      id
      ordering
      name
      description
      foregroundColor
      backgroundColor
    }
    activities(orderBy: {createdAt: asc}) {
      ...CoreActivityFields
    }
    incidentPhaseOrdering
    relevantIncidentPhase {
      id
      name
      foregroundColor
      backgroundColor
      ordering
    }
    roles {
      id
      roleId
      role {
        id
        name
        description
        isDeleted
        isEnabled
        users {
          userId
        }
      }
    }
    taskSnapshot {
      id
      data
    }
    obligations {
      obligation {
        id
        name
      }
    }
    documents {
      id
      documentId
      isSourceMaterial
      document {
        id
        name
        s3Key
        updatedAt
      }
    }
    chatMessages {
      ...CoreChatFields
    }
    meetingUsers {
      id
      userId
      actionId
      user {
        fullName
        email
      }
    }
  }
`;

const CREATE_ACTION = gql`
  ${CORE_ACTION_FIELDS}
  mutation CreateAction(
    $name: String!
    $description: String
    $actionType: ActionType
    $incidentId: Int!
    $phaseId: Int!
    $owner: UserCreateNestedOneWithoutActionsInput
    $acceptedAt: DateTime
    $deadlineAt: DateTime
    $nextRecursAt: DateTime
    $occurredAt: DateTime
    $recurrence: JSON
    $relevantIncidentPhase: IncidentPhaseCreateNestedOneWithoutActionsInput!
    $roles: ActionRoleCreateNestedManyWithoutActionInput
  ) {
    createOneAction(
      data: {
        name: $name
        description: $description
        actionType: $actionType
        acceptedAt: $acceptedAt
        deadlineAt: $deadlineAt
        nextRecursAt: $nextRecursAt
        occurredAt: $occurredAt
        incident: {connect: {id: $incidentId}}
        roles: $roles
        user: $owner
        recurrence: $recurrence
        relevantIncidentPhase: $relevantIncidentPhase
        phase: {connect: {id: $phaseId}}
      }
    ) {
      ...CoreActionFields
    }
  }
`;

const CREATE_EVENT = gql`
  ${CORE_ACTIVITY_FIELDS}
  ${CORE_ACTION_FIELDS}
  mutation CreateEvent(
    $eventName: String!
    $eventDescription: String!
    $incidentId: Int!
    $occurredAt: DateTime!
    $userId: Int!
    $phaseId: Int!
    $relevantIncidentPhaseId: Int!
  ) {
    createOneActivity(
      data: {
        name: "Event"
        description: $eventDescription
        action: {
          create: {
            name: $eventName
            actionType: event
            occurredAt: $occurredAt
            incident: {connect: {id: $incidentId}}
            user: {connect: {id: $userId}}
            relevantIncidentPhase: {connect: {id: $relevantIncidentPhaseId}}
            phase: {connect: {id: $phaseId}}
          }
        }
      }
    ) {
      ...CoreActivityFields
      action {
        ...CoreActionFields
      }
    }
  }
`;

const CREATE_MEETING = gql`
  ${CORE_ACTION_FIELDS}
  mutation CreateMeeting(
    $meetingName: String!
    $meetingDescription: String
    $actionType: ActionType
    $incidentId: Int!
    $phaseId: Int!
    $userId: Int!
    $nextRecursAt: DateTime
    $occurredAt: DateTime
    $recurrence: JSON
    $relevantIncidentPhase: IncidentPhaseCreateNestedOneWithoutActionsInput!
    $meetingUsers: [MeetingUsersCreateManyActionInput!]!
  ) {
    createOneAction(
      data: {
        name: $meetingName
        description: $meetingDescription
        actionType: $actionType
        incident: {connect: {id: $incidentId}}
        phase: {connect: {id: $phaseId}}
        user: {connect: {id: $userId}}
        nextRecursAt: $nextRecursAt
        occurredAt: $occurredAt
        recurrence: $recurrence
        relevantIncidentPhase: $relevantIncidentPhase
        meetingUsers: {createMany: {data: $meetingUsers}}
      }
    ) {
      ...CoreActionFields
    }
  }
`;

const GET_ACTION = gql`
  ${CORE_ACTION_FIELDS}
  query GetAction($id: Int!) {
    action(where: {id: $id}) {
      ...CoreActionFields
    }
  }
`;

const GET_ACTIONS_FOR_INCIDENT = gql`
  ${CORE_ACTION_FIELDS}
  query GetActionsForIncident($incidentId: Int!) {
    actions(where: {incidentId: {equals: $incidentId}}, orderBy: {id: desc}) {
      ...CoreActionFields
    }
  }
`;

const SET_ACTION_PHASE = gql`
  ${CORE_ACTION_FIELDS}
  mutation UpdateActionPhase($actionId: Int!, $phaseId: Int!) {
    updateOneAction(
      where: {id: $actionId}
      data: {phase: {connect: {id: $phaseId}}}
    ) {
      ...CoreActionFields
    }
  }
`;

const UPDATE_ACTION = gql`
  ${CORE_ACTION_FIELDS}
  mutation UpdateAction(
    $id: Int!
    $nameUpdateOperation: StringFieldUpdateOperationsInput
    $userConnector: UserUpdateOneWithoutActionsNestedInput
    $acceptedAt: NullableDateTimeFieldUpdateOperationsInput
    $nextRecursAt: NullableDateTimeFieldUpdateOperationsInput
    $occurredAt: NullableDateTimeFieldUpdateOperationsInput
    $phaseConnector: ActionPhaseUpdateOneRequiredWithoutActionsNestedInput
    $recurrence: JSON
    $roles: ActionRoleUpdateManyWithoutActionNestedInput
  ) {
    updateOneAction(
      where: {id: $id}
      data: {
        name: $nameUpdateOperation
        user: $userConnector
        acceptedAt: $acceptedAt
        nextRecursAt: $nextRecursAt
        occurredAt: $occurredAt
        phase: $phaseConnector
        recurrence: $recurrence
        roles: $roles
      }
    ) {
      ...CoreActionFields
    }
  }
`;

const DELETE_ACTIONS = gql`
  mutation DeleteActions($where: ActionWhereInput) {
    deleteManyAction(where: $where) {
      count
    }
  }
`;

const ASSIGN_ACTIONS = gql`
  ${CORE_ACTION_FIELDS}
  mutation AssignActions(
    $where: [ActionWhereUniqueInput!]
    $user: UserWhereUniqueInput!
  ) {
    updateManyActionUsers(where: $where, user: $user) {
      ...CoreActionFields
    }
  }
`;

const CREATE_ACTIVITY = gql`
  ${CORE_ACTIVITY_FIELDS}
  mutation CreateActivity(
    $actionId: Int!
    $name: String!
    $description: String!
  ) {
    createOneActivity(
      data: {
        name: $name
        description: $description
        action: {connect: {id: $actionId}}
      }
    ) {
      ...CoreActivityFields
    }
  }
`;

const CREATE_ACTIVITY_AND_UPDATE_ACTION = gql`
  ${CORE_ACTION_FIELDS}
  ${CORE_ACTIVITY_FIELDS}
  mutation CreateActivityAndUpdateAction(
    $actionId: Int!
    $name: String!
    $description: String!
    $actionPhase: ActionPhaseUpdateOneRequiredWithoutActionsNestedInput
    $actionUser: UserUpdateOneWithoutActionsNestedInput
  ) {
    createOneActivity(
      data: {
        name: $name
        description: $description
        action: {connect: {id: $actionId}}
      }
    ) {
      ...CoreActivityFields
    }
    updateOneAction(
      where: {id: $actionId}
      data: {phase: $actionPhase, user: $actionUser}
    ) {
      ...CoreActionFields
    }
  }
`;

const EDIT_ACTIVITY = gql`
  ${CORE_ACTIVITY_FIELDS}
  mutation UpdateOneActivity($activityId: Int!, $description: String!) {
    updateOneActivity(
      where: {id: $activityId}
      data: {description: {set: $description}}
    ) {
      ...CoreActivityFields
    }
  }
`;

const REWRITE_ACTIVITY = gql`
  query RewriteActivity(
    $taskName: String!
    $task: String!
    $userEntry: String!
  ) {
    rewriteActivity(taskName: $taskName, task: $task, userEntry: $userEntry)
  }
`;

const SOFT_DELETE_ACTIVITY = gql`
  ${CORE_ACTIVITY_FIELDS}
  mutation DeleteActivity($activityId: Int!) {
    deleteOneActivity(where: {id: $activityId}) {
      ...CoreActivityFields
    }
  }
`;

const GET_ACTIVITIES_FOR_ACTION = gql`
  ${CORE_ACTIVITY_FIELDS}
  query GetActivitiesForAction($actionId: Int!) {
    activities(orderBy: {id: desc}, where: {actionId: {equals: $actionId}}) {
      ...CoreActivityFields
    }
  }
`;

const GET_ACTIVITY_STATUS_LIST = gql`
  query GET_ACTIVITY_STATUS_LIST {
    __type(name: "ActivityStatus") {
      enumValues {
        name
      }
    }
  }
`;

const ADD_ACTION_FILES = gql`
  ${CORE_ACTION_FIELDS}
  mutation AddActionDocuments(
    $id: Int!
    $documentsToCreate: [ActionDocumentCreateManyActionInput!]!
  ) {
    updateOneAction(
      where: {id: $id}
      data: {documents: {createMany: {data: $documentsToCreate}}}
    ) {
      ...CoreActionFields
    }
  }
`;

const DELETE_ACTION_FILES = gql`
  ${CORE_ACTION_FIELDS}
  mutation DeleteActionDocuments($id: Int!, $documentsToDelete: [Int!]) {
    updateOneAction(
      where: {id: $id}
      data: {documents: {deleteMany: {documentId: {in: $documentsToDelete}}}}
    ) {
      ...CoreActionFields
    }
  }
`;

const CREATE_ACTION_MESSAGE = gql`
  ${CORE_CHAT_FIELDS}
  mutation CreateActionMessage($id: Int!, $message: String!, $userId: Int!) {
    updateOneAction(
      where: {id: $id}
      data: {
        chatMessages: {
          create: {message: $message, user: {connect: {id: $userId}}}
        }
      }
    ) {
      id
      chatMessages {
        ...CoreChatFields
      }
    }
  }
`;

const ADD_MEETING_USER = gql`
  ${CORE_ACTION_FIELDS}
  mutation AddMeetingUser(
    $id: Int!
    $meetingUsers: [MeetingUsersCreateManyActionInput!]!
  ) {
    updateOneAction(
      where: {id: $id}
      data: {meetingUsers: {createMany: {data: $meetingUsers}}}
    ) {
      ...CoreActionFields
    }
  }
`;

const DELETE_MEETING_USER = gql`
  ${CORE_ACTION_FIELDS}
  mutation DeleteMeetingUser($id: Int!, $meetingUserIds: [Int!]) {
    updateOneAction(
      where: {id: $id}
      data: {meetingUsers: {deleteMany: {userId: {in: $meetingUserIds}}}}
    ) {
      ...CoreActionFields
    }
  }
`;

const GET_ACTIONS_FOR_USER = gql`
  ${CORE_ACTION_FIELDS}
  query GetActionsForUser($userId: Int!) {
    actions(
      where: {
        userId: {equals: $userId}
        isDeleted: {equals: false}
        incident: {
          is: {
            AND: [
              {isDeleted: {equals: false}}
              {isArchived: {equals: false}}
              {state: {not: {equals: Closed}}}
            ]
          }
        }
      }
    ) {
      ...CoreActionFields
    }
    lastActionPhase: actionPhases(
      orderBy: {ordering: desc}
      where: {isDeleted: {equals: false}}
      take: 1
    ) {
      id
      ordering
      name
    }
  }
`;

const GET_ACTION_COUNT_FOR_USER = gql`
  query GetActionCountForUser($userId: Int!, $lastActionPhase: Int!) {
    actions(
      where: {
        userId: {equals: $userId}
        phase: {is: {id: {not: {equals: $lastActionPhase}}}}
        isDeleted: {equals: false}
        incident: {
          is: {
            AND: [
              {isDeleted: {equals: false}}
              {isArchived: {equals: false}}
              {state: {not: {equals: Closed}}}
            ]
          }
        }
      }
    ) {
      id
    }
  }
`;

export {
  GET_ACTION,
  SET_ACTION_PHASE,
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_AND_UPDATE_ACTION,
  EDIT_ACTIVITY,
  REWRITE_ACTIVITY,
  SOFT_DELETE_ACTIVITY,
  GET_ACTIONS_FOR_INCIDENT,
  GET_ACTIVITIES_FOR_ACTION,
  GET_ACTIVITY_STATUS_LIST,
  CREATE_ACTION,
  CREATE_EVENT,
  CREATE_MEETING,
  UPDATE_ACTION,
  ADD_ACTION_FILES,
  DELETE_ACTION_FILES,
  CREATE_ACTION_MESSAGE,
  CORE_ACTION_FIELDS,
  GET_ACTIONS_FOR_USER,
  GET_ACTION_COUNT_FOR_USER,
  ADD_MEETING_USER,
  DELETE_MEETING_USER,
  ASSIGN_ACTIONS,
  DELETE_ACTIONS,
};
