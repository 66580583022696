import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFormContext, useFormState} from "react-hook-form";
import {Grid, TextField, useTheme, useMediaQuery} from "@mui/material";
import FormGuide from "./FormGuide";
import UpgradeAdornment from "../UpgradeAdornment";
import {isValidString} from "../../resources/utils/stringUtils";
import {isFunction} from "underscore";
import {useCustomTextFieldStyles} from "./formsStyles";

const CustomTextField = ({
  name,
  label,
  type,
  guide,
  required,
  needUpgrade,
  noRows,
  multi,
  InputProps,
  hideErrors,
  errorText,
  defaultValue,
  isDisabled,
  hideOptionality,
  customOptionality,
  validate,
  autoComplete,
  autoFocus,
  customClasses,
  disableValidationCheck = false,
  fullWidth,
  size,
  onChange,
  isText,
  helperText,
  forceRegister = false,
}) => {
  const {classes, cx} = useCustomTextFieldStyles({needUpgrade: needUpgrade});
  const {register} = useFormContext();
  const {errors} = useFormState();
  const hidingErrors = !!hideErrors;
  const [validationError, setValidationError] = useState(false);
  const isError =
    Boolean(!hidingErrors && errors && errors[name]) ||
    (!disableValidationCheck && !hidingErrors && !!validationError);
  const isMultiline = multi > 0 || noRows;
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const itemWidth = guide && isNotMobile ? 8 : 12;

  useEffect(() => {
    if (forceRegister) {
      register(name, {required: required, validate: validate});
    }
  }, [forceRegister]);

  const handleChange = (event) => {
    const {isValid, error: characterError} = isValidString(event.target.value);

    if (!isValid) {
      setValidationError(`Invalid character ${characterError} used`);
    } else if (validationError) setValidationError(false);

    if (isFunction(onChange)) onChange(event);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={itemWidth}>
        <TextField
          data-tour={`text-field-${name}`}
          name={name}
          id={name}
          label={label}
          type={type || "text"}
          inputProps={register(name, {required: required, validate: validate})}
          InputProps={
            needUpgrade ?
              {...InputProps, endAdornment: <UpgradeAdornment item={label} />} :
              InputProps
          }
          error={isError}
          multiline={isMultiline}
          rows={isMultiline && !noRows ? multi : undefined}
          helperText={
            isError ?
              !!validationError ?
                validationError :
                errorText :
              helperText
          }
          fullWidth={fullWidth ?? true}
          margin="normal"
          variant="outlined"
          className={cx(classes.primary, customClasses)}
          defaultValue={defaultValue}
          disabled={isDisabled || needUpgrade}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          size={size || "medium"}
          onChange={handleChange}
        />
      </Grid>
      <FormGuide
        width={isNotMobile ? 3 : 12}
        guide={guide}
        required={required}
        hideOptionality={hideOptionality}
        customOptionality={customOptionality}
        needUpgrade={needUpgrade}
        disabled={isDisabled}
        isText={isText}
      />
    </Grid>
  );
};

CustomTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  guide: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  needUpgrade: PropTypes.bool,
  noRows: PropTypes.bool,
  multi: PropTypes.number,
  InputProps: PropTypes.object,
  hideErrors: PropTypes.bool,
  errorText: PropTypes.string,
  defaultValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  hideOptionality: PropTypes.bool,
  customOptionality: PropTypes.string,
  validate: PropTypes.func,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  customClasses: PropTypes.string,
  disableValidationCheck: PropTypes.bool,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  isText: PropTypes.bool,
  helperText: PropTypes.string,
  forceRegister: PropTypes.bool,
};

export default CustomTextField;
