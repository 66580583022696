import {
  each,
  filter,
  findWhere,
  isEmpty,
  isString,
  map,
  sortBy,
} from "underscore";

export const processAccessPolicies = (
  accessPolicies,
  targetPolicyNameSubstring,
) => {
  const relevantAccessPolicies = filter(
    accessPolicies,
    (accessPolicy) =>
      isString(accessPolicy?.name) &&
      accessPolicy.name.includes(targetPolicyNameSubstring),
  );
  const sortedRelevantPolicies = sortBy(relevantAccessPolicies, "name");
  return map(sortedRelevantPolicies, (relevantPolicy) => {
    const name = relevantPolicy.name
      .replaceAll(targetPolicyNameSubstring, "")
      .replaceAll("_", " ")
      .slice(1);
    return {
      name: `${name[0].toUpperCase()}${name.slice(1)}`,
      id: relevantPolicy.id,
      isDisabled: relevantPolicy.isDisabled,
    };
  });
};

export const processExactKeyValuePairSettings = (
  organizationSettings,
  settings,
) => {
  if (isEmpty(organizationSettings)) return {};

  const keyValuePairSettings = {};
  each(settings, (key) => {
    const setting = findWhere(organizationSettings, {key});
    keyValuePairSettings[key] = setting?.value;
    keyValuePairSettings[`${key}Setting`] = setting;
  });
  return keyValuePairSettings;
};

export const processTrueFalseSettings = (organizationSettings, settings) => {
  if (isEmpty(organizationSettings)) return {};

  const trueFalseSettings = {};
  each(settings, (key) => {
    const setting = findWhere(organizationSettings, {key});
    trueFalseSettings[key] =
      setting?.value === "true" || setting?.value === true;
    trueFalseSettings[`${key}Setting`] = setting;
  });
  return trueFalseSettings;
};

export const processAIFormSettings = (settings) => {
  const aiFormSettings = {};
  each(settings, (permissionSetting) => {
    aiFormSettings[permissionSetting.name] = permissionSetting.defaultChecked;
  });
  return aiFormSettings;
};
