import React from "react";
import {Box, InputAdornment, Typography} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import axios from "axios";
import CustomTextField from "../../forms/CustomTextField";
import {useAuthStyles} from "../AuthStyles";
import {SubmitButton} from "../../buttons";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {mfaSchema} from "../schemas";
import {useAuth} from "../Context/AuthProvider";
import MFAContainer from "./MFAContainer";
import {isEmpty} from "underscore";
import PropTypes from "prop-types";

const AUTH_ENDPOINT = process.env.AUTH_ENDPOINT;

const MFAChallengeForm = ({mfaMethod}) => {
  const {classes} = useAuthStyles();
  const {
    authSession,
    setAuthSession,
    setErrorMessage,
    setIsAuthLoading,
    isAuthLoading,
    orgName,
  } = useAuth();
  const formMethods = useForm({resolver: yupResolver(mfaSchema)});

  const submitMfa = async (data) => {
    setIsAuthLoading(true);
    try {
      const challengeResponseCodeKey = `${mfaMethod}_CODE`;
      const response = await axios.post(`${AUTH_ENDPOINT}/login/mfa`, {
        orgName,
        ChallengeName: mfaMethod,
        ChallengeResponses: {
          USERNAME: authSession.username,
          [challengeResponseCodeKey]: data.mfaCode,
        },
        Session: authSession.Session,
      });
      if (response.status === 200 && !isEmpty(response?.data)) {
        setAuthSession({
          ...response.data,
          rememberMe: authSession.rememberMe,
          username: authSession.username,
        });
        setErrorMessage("");
      } else {
        setErrorMessage("Login unsuccessful.");
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ??
          error?.message ??
          JSON.stringify(error),
      );
    } finally {
      setIsAuthLoading(false);
    }
  };

  let friendlyAuthMethodMessage;
  if (authSession.ChallengeName === "SMS_MFA") {
    const phone = authSession.ChallengeParameters.CODE_DELIVERY_DESTINATION;
    friendlyAuthMethodMessage = `We sent you a code via SMS to your phone at ${phone}`;
  } else if (authSession.ChallengeName === "SOFTWARE_TOKEN_MFA") {
    friendlyAuthMethodMessage =
      "Please enter the code displayed in your Authenticator app.";
  } else {
    friendlyAuthMethodMessage = "";
  }

  return (
    <MFAContainer>
      <Typography mt={3}>{friendlyAuthMethodMessage}</Typography>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(submitMfa)}
          className={classes.form}
        >
          <CustomTextField
            name="mfaCode"
            label="One-Time Code"
            required
            disableValidationCheck
            autoComplete="off"
            autoFocus
            errorText="Valid One-Time Code is required"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlinedIcon
                    className={classes.adornmentIcon}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Box mt={3}>
            <SubmitButton isLoading={isAuthLoading}>Submit</SubmitButton>
          </Box>
        </form>
      </FormProvider>
    </MFAContainer>
  );
};

MFAChallengeForm.propTypes = {
  mfaMethod: PropTypes.string.isRequired,
};

export default MFAChallengeForm;
