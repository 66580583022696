import {gql} from "@apollo/client";

const CORE_POLICY_FIELDS = gql`
  fragment CorePolicyFields on AccessPolicy {
    id
    name
    action
    subject
    conditions
    subject
    fields
    inverted
    isDisabled
  }
`;

const CORE_ROLE_FIELDS = gql`
  fragment CoreAccessPolicyRoleFields on AccessPolicyRole {
    id
    roleId
  }
`;

const GET_POLICY = gql`
  ${CORE_POLICY_FIELDS}
  ${CORE_ROLE_FIELDS}
  query GetPolicy($name: String!) {
    accessPolicies(where: {name: {equals: $name}}) {
      ...CorePolicyFields
      roles {
        ...CoreAccessPolicyRoleFields
      }
    }
  }
`;

const UPDATE_POLICY = gql`
  ${CORE_POLICY_FIELDS}
  ${CORE_ROLE_FIELDS}
  mutation UpdatePolicy(
    $policyId: Int!
    $roles: AccessPolicyRoleUpdateManyWithoutAccessPolicyNestedInput
  ) {
    updateOneAccessPolicy(where: {id: $policyId}, data: {roles: $roles}) {
      ...CorePolicyFields
      roles {
        ...CoreAccessPolicyRoleFields
      }
    }
  }
`;

const UPDATE_MANY_POLICIES = gql`
  mutation UpdateManyPolicies(
    $policyIds: [Int!]!
    $isDisabled: BoolFieldUpdateOperationsInput!
  ) {
    updateManyAccessPolicy(
      where: {id: {in: $policyIds}}
      data: {isDisabled: $isDisabled}
    ) {
      count
    }
  }
`;

const GET_POLICIES = gql`
  ${CORE_POLICY_FIELDS}
  ${CORE_ROLE_FIELDS}
  query GetPolicies {
    accessPolicies {
      ...CorePolicyFields
      roles {
        ...CoreAccessPolicyRoleFields
      }
    }
  }
`;

const GET_POLICIES_WITHOUT_ROLES = gql`
  ${CORE_POLICY_FIELDS}
  query GetPoliciesWithoutRoles {
    accessPolicies(where: {roles: {none: {}}}, orderBy: {id: asc}) {
      ...CorePolicyFields
    }
  }
`;

export {
  GET_POLICY,
  GET_POLICIES,
  GET_POLICIES_WITHOUT_ROLES,
  UPDATE_POLICY,
  UPDATE_MANY_POLICIES,
};
